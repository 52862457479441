import { ActionType } from '../action-types';
import { Action } from '../actions';
import { getLastItem } from '../../utils';

export type TFilesData = {
  id?: number;
  filename: string;
  uploadingState: 'uploading' | 'processing' | 'completed' | 'error';
  completed: number;
  subtitle?: string;
  error?: string;
  showDelete?: boolean;
};

export const releaseFormsInitialState: TFilesData[] = [];

const getLoadedFilesMap = (
  state: TFilesData[],
  files: File[]
): TFilesData[] => {
  let acceptedFiles: TFilesData[] = [];

  acceptedFiles = files.map((file) => {
    const findFile = state.find((data) => data.filename === file.name);
    if (findFile) {
      return {
        filename: file.name,
        uploadingState: 'error',
        completed: 0,
        error: 'This file is already uploaded',
      };
    } else {
      return {
        filename: file.name,
        uploadingState: 'uploading',
        completed: 0,
      };
    }
  });

  return [...state, ...acceptedFiles];
};

const setFiles = (state: TFilesData[], payload: { url: string }[]) => {
  let files: TFilesData[] = [];

  files = payload.map((data) => {
    return {
      filename: getLastItem(data.url),
      uploadingState: 'completed',
      completed: 100,
      showDelete: true,
    };
  });

  let data = [...files];
  let filter = data.filter((c, index) => {
    return data.indexOf(c) === index;
  });

  return [...filter];
};

const releaseFormsReducer = (
  state: TFilesData[] = releaseFormsInitialState,
  action: Action
): TFilesData[] => {
  switch (action.type) {
    case ActionType.SET_LOAD_FILES:
      return getLoadedFilesMap(state, action.payload);
    case ActionType.SET_UPLOAD_PROGRESS_FILE:
      const index = state.findIndex(
        (obj) => obj.filename === action.payload.filename
      );
      return [
        ...state.slice(0, index),
        {
          ...state[index],
          id: action.payload.id || 0,
          completed: action.payload.completed || 0,
          uploadingState:
            action.payload.completed === 100
              ? 'completed'
              : action.payload.error
              ? 'error'
              : 'uploading',
          showDelete: true,
          error: action.payload.error ? action.payload.error : '',
        },
        ...state.slice(index + 1),
      ];
    case ActionType.SET_CANCEL_UPLOAD_FILE:
      const indexFileCanceled = state.findIndex(
        (obj) => obj.filename === action.payload.filename
      );
      return [
        ...state.slice(0, indexFileCanceled),
        {
          ...state[indexFileCanceled],
          uploadingState: 'processing',
          subtitle:
            state[indexFileCanceled].completed === 100
              ? 'Deleting...'
              : 'Cancelling...',
          showDelete: false,
        },
        ...state.slice(indexFileCanceled + 1),
      ];
    case ActionType.SET_REMOVE_CANCELED_FILE:
      const newState = state.filter(
        (item) => item.filename !== action.payload.filename
      );
      return newState;
    case ActionType.SET_FILES:
      return setFiles(state, action.payload);
    default:
      return state;
  }
};

export default releaseFormsReducer;
