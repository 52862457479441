import { SpecStatus } from './types';

export const SIGNUP_URL: string = 'https://x4y.com/creators/signup';

//Timeouts
export const PLAY_ANIMATION_TIMEOUT: number = 300;
export const SNACKBAR_TIMEOUT: number = 5000;
export const ACTION_DELAY: number = 2000;
export const ERROR_MESSAGE_DELAY: number = 3000;

//Sizes
export const WINDOW_XS: number = 463;
export const WINDOW_SM: number = 632;
export const WINDOW_MD: number = 768;

//Spec Status
export const specStatus: {
  PROCESSING: SpecStatus;
  DRAFT: SpecStatus;
  PENDING_REVIEW: SpecStatus;
  CHANGES_REQUIRED: SpecStatus;
  REJECTED: SpecStatus;
  APPROVED: SpecStatus;
  SOLD: SpecStatus;
  DELETED: SpecStatus;
} = {
  PROCESSING: 'processing',
  DRAFT: 'draft',
  PENDING_REVIEW: 'pending review',
  CHANGES_REQUIRED: 'changes required',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  SOLD: 'sold',
  DELETED: 'deleted',
};

//Misc
export const MAX_PROFESSIONS: number = 4;

export const DEMO_THUMBNAIL: string =
  'https://image.mux.com/9NJNZgCk7idxGUdu6Cgcro00PPwpU5UJLnsLHstKzoMk/thumbnail.png?time=2';

export const DEMO_VIDEO: string =
  'https://stream.mux.com/9NJNZgCk7idxGUdu6Cgcro00PPwpU5UJLnsLHstKzoMk.m3u8';

export const MUX_TIMEOUT_INTERVAL = 750;

export const MUX_TIMEOUT_TRIES = 3;

export const THUMBNAIL_SIZE_LIMIT = 3000000; // in bytes.

export const VIDEO_SIZE_LIMIT = 500000000; // in bytes.
