import React, { useReducer, createContext, ReactNode } from 'react';
import combineReducers from 'react-combine-reducers';
import specReducer, { initialState, AppState } from './reducers';
import releaseFormReducer, {
  TFilesData,
  releaseFormsInitialState,
} from './reducers/releaseFormsReducer';
import { Action } from './actions';
import specInfoReducer, {
  specInfoInitialState,
  SpecInfoInitialStateType,
} from './reducers/specInfoReducer';
import userInfoReducer, {
  userInfoInitialState,
  UserInfoInitialStateType,
} from './reducers/editProfileReducer';
import userSettingsDataReducer, {
  userSettingsDataInitialState,
  UserSettingsDataInitialStateType,
} from './reducers/settingsProfileReducer';

import notificationReducer, {
  notificationInitialState,
} from './reducers/notificationReducer';
import { NotificationsResponseModel } from './types/databaseSchemas';

type ContextProps = {
  children?: ReactNode;
};

type IState = {
  specState: AppState;
  releaseFormState: TFilesData[];
  specInfo: SpecInfoInitialStateType;
  editProfile: UserInfoInitialStateType;
  settingsData: UserSettingsDataInitialStateType;
  notifications: NotificationsResponseModel[];
};

interface IStore {
  state: IState;
  dispatch: React.Dispatch<Action>;
}

const initState: IState = {
  specState: initialState,
  releaseFormState: releaseFormsInitialState,
  specInfo: specInfoInitialState,
  editProfile: userInfoInitialState,
  settingsData: userSettingsDataInitialState,
  notifications: notificationInitialState,
};

type allReducers = (state: IState, action: Action) => IState;

const [reducers, initialReducers] = combineReducers<allReducers>({
  specState: [specReducer, initialState],
  releaseFormState: [releaseFormReducer, releaseFormsInitialState],
  specInfo: [specInfoReducer, specInfoInitialState],
  editProfile: [userInfoReducer, userInfoInitialState],
  settingsData: [userSettingsDataReducer, userSettingsDataInitialState],
  notifications: [notificationReducer, notificationInitialState],
});

const store = createContext<IStore>({
  state: initState,
  dispatch: () => null,
});

const { Provider } = store;

const AppProvider = ({ children }: ContextProps) => {
  const [state, dispatch] = useReducer<allReducers>(reducers, initialReducers);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, AppProvider };
