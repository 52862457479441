import React from 'react';
import Router from 'next/router';

type Props = {
  children?: React.ReactNode;
};

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  window.analytics.page(url);
});

const Page = ({ children }: Props) => <div>{children}</div>;

export default Page;
