import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { AppProvider } from '../state/context';
import Head from 'next/head';
import Script from 'next/script';
import Page from '../components/Page';
import * as snippet from '@segment/snippet';

const DEFAULT_WRITE_KEY = 'vTS2XkqcEqVUd9hjv0Nfta3S9H38mggX';

function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY || DEFAULT_WRITE_KEY,
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Page>
      <AppProvider>
        <Script
          id='segment-script'
          dangerouslySetInnerHTML={{ __html: renderSnippet() }}
        />
        <Head>
          <title>X4Y Marketplace</title>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
            key='viewport'
          />
          <link rel='icon' href='/favicon.ico' />
        </Head>
        <Component {...pageProps} />
      </AppProvider>
    </Page>
  );
}
export default MyApp;
