import { ActionType } from '../action-types';
import { Action } from '../actions';
import { NotificationsResponseModel } from '../types/databaseSchemas';

export const notificationInitialState: NotificationsResponseModel[] = [];

const notificationReducer = (
  state: NotificationsResponseModel[] = notificationInitialState,
  action: Action
): NotificationsResponseModel[] => {
  switch (action.type) {
    case ActionType.SET_ALL_NOTIFICATIONS:
      return [...action.payload, ...state ];
    case ActionType.SET_NOTIFICATION_AS_READ:
      return [...action.payload];
    default:
      return state;
  }
};

export default notificationReducer;
