import { ActionType } from '../action-types';
import { Action } from '../actions';
import { UserSettingsData } from '../types';

export type UserSettingsDataInitialStateType = UserSettingsData;

export const userSettingsDataInitialState: UserSettingsDataInitialStateType = {
  name: '',
  email: '',
  date_of_birth: '',
  termsAcceptance: null,
};

const userSettingsDataReducer = (
  state: UserSettingsData,
  action: Action
): UserSettingsData => {
  switch (action.type) {
    case ActionType.SET_USER_SETTINGS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userSettingsDataReducer;
