import { supabase } from '../../utils/supabase';
import { ActionType } from '../action-types';
import { UserSuccess } from '../types';

export const useAuth = () => {
  const item = 'app.x4y.user';
  const supabaseSession = 'supabase.auth.token';

  const setAuthUserSuccess = (data: UserSuccess) => {
    setAuthLocalStorage(ActionType.SET_AUTH_USER_SUCCESS, data);
  };

  const setAuthUserError = (error: string) => {
    setAuthLocalStorage(ActionType.SET_AUTH_USER_ERROR, error);
  };

  const setAuthUserNewToken = (token: string) => {
    setAuthLocalStorage(ActionType.SET_AUTH_USER_NEWTOKEN, token);
  };

  const setAuthLogOut = () => {
    setAuthLocalStorage(ActionType.SET_AUTH_USER_LOGOUT);
  };

  const setAuthLocalStorage = (
    type: ActionType,
    data?: UserSuccess | string
  ) => {
    switch (type) {
      case ActionType.SET_AUTH_USER_SUCCESS:
        let info = data;
        localStorage.setItem(item, JSON.stringify(info));
        return;
      case ActionType.SET_AUTH_USER_ERROR:
        localStorage.removeItem(item);
        return;
      case ActionType.SET_AUTH_USER_NEWTOKEN:
        let prevInfo: UserSuccess = JSON.parse(
          localStorage.getItem(item) || ''
        );
        let newInfo: UserSuccess = { ...prevInfo, token: data as string };
        localStorage.removeItem(item);
        localStorage.setItem(item, JSON.stringify(newInfo));
        return;
      case ActionType.SET_AUTH_USER_LOGOUT:
        localStorage.removeItem(item);
        localStorage.removeItem(supabaseSession);
        supabase.auth.signOut();
        return;
    }
  };

  const isUserAuthenticated = () => {
    const local = localStorage.getItem(item);
    const supabaseData = localStorage.getItem(supabaseSession);

    if (!local || !supabaseData) {
      return false;
    }

    return true;
  };

  const getAuthUserInfo = (): UserSuccess | null => {
    const local: UserSuccess | null = localStorage.getItem(item)
      ? JSON.parse(localStorage.getItem(item)!)
      : null;

    return local;
  };

  return {
    setAuthUserSuccess,
    setAuthUserError,
    setAuthUserNewToken,
    setAuthLogOut,
    isUserAuthenticated,
    getAuthUserInfo,
  };
};

export default useAuth;
