import axios from 'axios';
import { useAuth } from '../../state/hooks/useAuth';

const axiosRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_VERCEL_URL,
});

axiosRequest.interceptors.request.use((config) => {
  const { setAuthUserNewToken } = useAuth();
  let session = JSON.parse(localStorage.getItem('app.x4y.user') || '');

  if (localStorage.getItem('supabase.auth.token')) {
    const supabaseSession = JSON.parse(
      localStorage.getItem('supabase.auth.token') || ''
    );
    if (supabaseSession.currentSession.access_token !== session.token) {
      setAuthUserNewToken(supabaseSession.currentSession.access_token);
      session.token = supabaseSession.currentSession.access_token;
    }
  }

  config.headers!['Authorization'] = session ? `Bearer ${session.token}` : '';

  return config;
});

export default axiosRequest;
