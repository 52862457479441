import { ActionType } from '../action-types';
import { Action } from '../actions';
import { SpecInfoData } from '../types';
import { specStatus } from '../../utils/constants';
import { SpecStatus } from '../../utils/types';

export type SpecInfoInitialStateType = {
  specData: SpecInfoData;
  specDataNew: SpecInfoData;
  specId: string | number;
  availableKeywords: any[];
};

const emptySpec: SpecInfoData = {
  title: '',
  specType: '',
  description: '',
  price: 0,
  includedInSale: '',
  additionalServices: '',
  brands: '',
  keywords: [],
  videourl: '',
  thumbnailurl: '',
};

export const specInfoInitialState: SpecInfoInitialStateType = {
  specData: emptySpec,
  specDataNew: emptySpec,
  specId: '',
  availableKeywords: [],
};

const getSpecStatus = (status: string) => {
  return status === specStatus.PROCESSING ? specStatus.DRAFT : status;
};

const specInfoReducer = (
  state: SpecInfoInitialStateType = specInfoInitialState,
  action: Action
): SpecInfoInitialStateType => {
  switch (action.type) {
    case ActionType.SET_SPEC_ID:
      return {
        ...state,
        specId: action.payload,
      };
    case ActionType.START_SPEC_INFO:
      return {
        ...state,
        specData: action.payload,
        specDataNew: action.payload,
      };
    case ActionType.SET_SPEC_INFO:
      return {
        ...state,
        specData: { ...state.specData, ...action.payload },
        specDataNew: { ...state.specDataNew, ...action.payload },
      };
    case ActionType.SET_SPEC_INFO_PARAM:
      return {
        ...state,
        specDataNew: {
          ...state.specDataNew,
          [action.payload.key]: action.payload.value,
        },
      };
    case ActionType.SET_SPEC_THUMBNAIL:
      return {
        ...state,
        specDataNew: {
          ...state.specDataNew,
          thumbnailurl: action.payload,
        },
        specData: {
          ...state.specData,
          thumbnailurl: action.payload,
        },
      };
    case ActionType.SET_AVAILABLE_KEYWORDS:
      return {
        ...state,
        availableKeywords: action.payload,
      };
    case ActionType.SET_ADD_KEYWORD:
      const prevState = state.specDataNew.keywords
        ? state.specDataNew.keywords
        : [];
      return {
        ...state,
        specDataNew: {
          ...state.specDataNew,
          keywords: [
            {
              id: action.payload.id,
              category_id: action.payload.category_id,
              category: action.payload.category_name,
              keyword_id: action.payload.keyword_id,
              label: action.payload.label,
              status: 'colored',
            },
            ...prevState,
          ],
        },
      };
    case ActionType.SET_KEYWORDS_UPDATE:
      const index = state.specDataNew.keywords!.findIndex(
        (obj) => obj.id === action.payload.id
      );
      return {
        ...state,
        specDataNew: {
          ...state.specDataNew,
          keywords: [
            ...state.specDataNew.keywords!.slice(0, index),
            {
              ...state.specDataNew.keywords![index],
              id: action.payload.id,
              status: action.payload.status,
            },
            ...state.specDataNew.keywords!.slice(index + 1),
          ],
        },
      };
    case ActionType.SET_KEYWORDS_REMOVE:
      const newState = state.specDataNew.keywords?.filter(
        (item) =>
          item.id !== action.payload.id ||
          (item.label === action.payload.label &&
            item.category === action.payload.category)
      );
      return {
        ...state,
        specDataNew: {
          ...state.specDataNew,
          keywords: newState,
        },
      };
    case ActionType.SET_KEYWORDS:
      state.specDataNew.keywords = [];
      const keywords =
        action.payload && action.payload.length > 0
          ? action.payload[0].spec_id == state.specId
            ? action.payload?.map((data) => {
                return {
                  id: data.id,
                  keyword_id: data.keyword_id,
                  spec_id: data.spec_id,
                  category_id: data.category_id,
                  category: data.category_name,
                  label: data.label,
                  status: data.status,
                  spec_status: data.spec_status,
                };
              })
            : []
          : [];
      return {
        ...state,
        specDataNew: {
          ...state.specDataNew,
          status:
            keywords && keywords.length > 0
              ? (keywords![0].spec_status as SpecStatus)
              : (getSpecStatus(state.specData.status as string) as SpecStatus),
          keywords,
        },
        specData: {
          ...state.specData,
          status:
            keywords && keywords.length > 0
              ? (keywords![0].spec_status as SpecStatus)
              : (getSpecStatus(state.specData.status as string) as SpecStatus),
          keywords,
        },
      };
    default:
      return state;
  }
};

export default specInfoReducer;
