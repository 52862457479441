import { ActionType } from '../action-types';
import { Action } from '../actions';
import { Spec, User, Video, Profession, InviteInfo, SubType } from '../types';

export type AppState = {
  loading: boolean;
  creatorId: string;
  screenLoading: boolean;
  loggedIn: boolean;
  snackbar: {
    variant: 'pending' | 'error' | 'success' | undefined;
    message: string | null;
  };
  specs: any[];
  professions: Profession[];
  specTypes: SubType[];
  spec: Spec;
  video: Video;
  user: User;
  inviteInfo: InviteInfo;
  specDuration: number;
  glittering: number | string;
  introMessage: string;
  introStatus: 'idle' | 'error' | 'success';
};

export const initialState: AppState = {
  loading: false,
  screenLoading: false,
  loggedIn: false,
  snackbar: {
    variant: undefined,
    message: null,
  },
  creatorId: '',
  professions: [],
  specTypes: [],
  inviteInfo: {
    invitationsAllowed: 0,
    invitationLink: '',
    invitationCode: '',
    memberSince: '',
    invitedBy: {},
  },
  specs: [],
  spec: {
    title: '',
  },
  video: {
    name: '',
    type: '',
    size: 0,
    file: null,
    uploadId: '',
    isUploading: false,
    isUploaded: false,
    progress: 0,
  },
  user: {
    name: '',
  },
  specDuration: 0,
  glittering: '',
  introMessage: '',
  introStatus: 'idle',
};

const specReducer = (
  state: AppState = initialState,
  action: Action
): AppState => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        user: action.payload,
      };
    case ActionType.SET_INVITATION_DATA:
      return {
        ...state,
        inviteInfo: action.payload,
      };
    case ActionType.SET_LOAD_VIDEO:
      return {
        ...state,
        loading: false,
        snackbar: {
          variant: undefined,
          message: null,
        },
        video: {
          name: action.payload.name,
          type: action.payload.type,
          size: 0,
          file: action.payload.file,
        },
      };
    case ActionType.RESET_LOAD_VIDEO:
      return {
        ...state,
        loading: false,
        snackbar: {
          variant: undefined,
          message: null,
        },
        video: {
          name: '',
          type: '',
          size: 0,
          file: null,
          uploadId: '',
          isUploading: false,
          isUploaded: false,
          progress: 0,
        },
      };
    case ActionType.SET_LOAD_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        snackbar: {
          variant: 'error',
          message: action.payload,
        },
        video: {
          name: '',
          type: '',
          size: 0,
          file: null,
          isUploading: false,
          isUploaded: false,
        },
      };
    case ActionType.SET_VIDEO_UPLOAD_ID:
      return {
        ...state,
        loading: true,
        video: {
          ...state.video,
          uploadId: action.payload,
          isUploading: true,
          isUploaded: false,
        },
      };
    case ActionType.SET_VIDEO_UPLOAD_PROGRESS:
      return {
        ...state,
        loading: true,
        video: {
          ...state.video,
          progress: action.payload,
          isUploading: true,
          isUploaded: false,
        },
      };
    case ActionType.SET_VIDEO_UPLOADED:
      return {
        ...state,
        loading: false,
        video: {
          ...state.video,
          progress: 100,
          isUploaded: true,
          isUploading: false,
        },
      };
    case ActionType.SET_ERROR:
      return {
        ...state,
        loading: false,
        video: {
          name: '',
          type: '',
          size: 0,
          file: null,
          isUploaded: false,
          isUploading: false,
        },
        snackbar: {
          variant: 'error',
          message: action.payload,
        },
      };
    case ActionType.SET_VIDEO_UPLOAD_CANCEL:
    case ActionType.SET_VIDEO_CANCEL:
      return {
        ...state,
        loading: false,
        video: {
          name: '',
          type: '',
          size: 0,
          file: null,
          isUploaded: false,
          isUploading: false,
          progress: 0,
        },
        snackbar: {
          variant: undefined,
          message: null,
        },
      };
    case ActionType.SET_PROFESSIONS:
      return {
        ...state,
        professions: action.payload,
      };
    case ActionType.SET_SPEC_TYPES:
      return {
        ...state,
        specTypes: action.payload,
      };
    case ActionType.SET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          variant: action.payload.variant,
          message: action.payload.message,
        },
      };
    case ActionType.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ActionType.START_LOADING_SCREEN:
      return {
        ...state,
        screenLoading: true,
      };
    case ActionType.STOP_LOADING_SCREEN:
      return {
        ...state,
        screenLoading: false,
      };
    case ActionType.SET_SPECS:
      return {
        ...state,
        specs: action.payload,
      };
    case ActionType.SET_GLITTERING:
      return {
        ...state,
        glittering: action.payload,
      };
    case ActionType.SET_CREATORID:
      return {
        ...state,
        creatorId: action.payload,
      };
    case ActionType.SET_INTRO_MESSAGE:
      return {
        ...state,
        introMessage: action.payload.message,
        introStatus: action.payload.status,
      };
    case ActionType.CLOSE_INTRO_MESSAGE:
      return {
        ...state,
        introMessage: '',
        introStatus: 'idle',
      };
    default:
      return state;
  }
};

export default specReducer;
