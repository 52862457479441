import { ActionType } from '../action-types';
import { Action } from '../actions';
import { UserInfoData } from '../types';

export type UserInfoInitialStateType = {
  userData: UserInfoData;
  userDataNew: UserInfoData;
  openModal: boolean;
};

const initialData: UserInfoData = {
  displayName: '',
  username: '',
  location: { plus_code: '' },
  onlinePortfolio: '',
  professions: [],
  headline: '',
  introduction: '',
  thumbnailurl: '',
  videourl: '',
  bio: '',
  customUrl: '',
  socialNetworks: {},
  termsAcceptance: '',
};

export const userInfoInitialState: UserInfoInitialStateType = {
  userData: initialData,
  userDataNew: initialData,
  openModal: false,
};

const userInfoReducer = (
  state: UserInfoInitialStateType = userInfoInitialState,
  action: Action
): UserInfoInitialStateType => {
  switch (action.type) {
    case ActionType.SET_USER_INFO:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
        userDataNew: { ...state.userDataNew, ...action.payload },
      };
    case ActionType.SET_INTRO_VIDEO:
      return {
        ...state,
        userData: { ...state.userData, videourl: action.payload },
        userDataNew: { ...state.userDataNew, videourl: action.payload },
      };
    case ActionType.SET_INTRO_THUMBNAIL:
      return {
        ...state,
        userData: { ...state.userData, thumbnailurl: action.payload },
        userDataNew: { ...state.userDataNew, thumbnailurl: action.payload },
      };
    case ActionType.SET_USER_INFO:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
        userDataNew: { ...state.userDataNew, ...action.payload },
      };
    case ActionType.SET_USER_INFO_PARAM:
      return {
        ...state,
        userDataNew: {
          ...state.userDataNew,
          [action.payload.key]: action.payload.value,
        },
      };
    case ActionType.SET_USER_INFO_SOCIAL_NETWORK:
      return {
        ...state,
        userDataNew: {
          ...state.userDataNew,
          socialNetworks: action.payload,
        },
      };
    case ActionType.OPEN_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ActionType.CLOSE_MODAL:
      return {
        ...state,
        openModal: false,
      };
    default:
      return state;
  }
};

export default userInfoReducer;
