import { NextRouter } from 'next/router';
import { useEffect, useState } from 'react';
import axiosRequest from '../config/axios/axios';
import { SocialNetworkLabel } from '../state/types';
import { isAllowed } from './blacklist';
import {
  MUX_TIMEOUT_INTERVAL,
  SNACKBAR_TIMEOUT,
  specStatus,
} from './constants';
import { SpecStatus } from './types';

export const capitalize = (word: string): string => {
  return word[0].toUpperCase() + word.slice(1);
};

export const convertFromUrl = (words: string): string => {
  let separated = words.split('-');
  separated = separated.map((word) => {
    return capitalize(word);
  });
  return separated.join(' ');
};

export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const secondsToMinutes = (seconds: number) => {
  return (
    (seconds / 60 < 1 ? '' : (seconds / 60).toFixed()) +
    ':' +
    (seconds % 60 < 10 ? '0' : '') +
    (seconds % 60)
  );
};

export const checkEmail = (email: string | undefined): boolean => {
  if (typeof email === 'undefined') return false;
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};

export const checkName = (name: string | undefined): boolean => {
  if (typeof name === 'undefined') return false;
  const re = /^[âêîôûÂÊÎÔÛàèìòùÀÈÌÒÙäëïöüÄËÏÖÜáéíóúÁÉÍÓÚñÑa-zA-Z0-9\s\-\']+$/;
  return (
    re.test(name) && name.length >= 2 && name.length <= 50 && isAllowed(name)
  );
};

export const checkTitle = (name: string | undefined): boolean => {
  if (typeof name === 'undefined') return false;
  return name.length >= 2 && name.length <= 50 && isAllowed(name);
};

export const checkUrl = (url: string | undefined): boolean => {
  if (typeof url === 'undefined') return false;
  const re =
    /^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/gi;
  return re.test(url);
};

export const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const compareObjects = (objectA: any, objectB: any): boolean => {
  let different = false;

  if (!objectA || !objectB) return false;
  if (typeof objectA !== typeof objectB) return true;
  if (typeof objectA === 'string' || typeof objectA === 'number')
    return objectA !== objectB;
  if (Array.isArray(objectA) && Array.isArray(objectB)) {
    if (Object.keys(objectA).length !== Object.keys(objectB).length)
      return true;

    return objectA.some((element, index) =>
      compareObjects(element, objectB[index])
    );
  }

  Object.keys(objectA).forEach((key) => {
    if (objectA[key] !== objectB[key]) different = true;
  });

  return different;
};

export const checkHandle = (handle: string): boolean => {
  if (typeof handle === 'undefined') return false;
  const re = /^[a-zA-Z0-9\-\'\.\_]+$/;
  const re2 =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return re.test(handle) && !re2.test(handle);
};

export const checkSocialNetworkHandle = (
  media: SocialNetworkLabel,
  value: string
): boolean => {
  switch (media) {
    case 'twitter':
      return (
        value.length >= 4 && value.length <= 15 && /^[a-z0-9\_]+$/i.test(value)
      );
    case 'youtubechannel':
    case 'instagram':
      return value.length <= 30 && /^[a-z0-9\_\.]+$/i.test(value);
    case 'dribbble':
      return (
        value.length >= 3 &&
        value.length <= 100 &&
        /^[a-z0-9\_\-]+$/i.test(value)
      );

    case 'linkedin':
      return (
        value.length >= 3 && value.length <= 100 && /^[a-z0-9]+$/i.test(value)
      );
    case 'facebook':
      return (
        value.length >= 5 && value.length <= 100 && /^[a-z0-9\.]+$/i.test(value)
      );
    case 'tiktok':
      return (
        value.length >= 4 &&
        value.length <= 24 &&
        /^[a-z0-9\_\.\@]+$/i.test(value) &&
        value[value.length - 1] !== '.' &&
        value.indexOf('@') < 1
      );
    case 'vimeo':
    case 'freethework':
    case 'substack':
      return value.length <= 100 && /^[a-z0-9]+$/i.test(value);
    default:
      return false;
  }
};

export const checkCustomUrl = async (
  url: string | undefined
): Promise<boolean> => {
  if (typeof url === 'undefined') return false;
  const re = /^[a-zA-Z0-9\-\.\_]+$/;
  if (
    re.test(url) &&
    isAllowed(url) &&
    url.indexOf(' ') < 0 &&
    url.length >= 5
  ) {
    try {
      const response = await axiosRequest(`/api/creators/validations/${url}`);
      return response.status == 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  } else return false;
};

export const displayLocalMessage = (
  setStatus: Function,
  setMessage: Function,
  status: 'idle' | 'error' | 'success',
  message: string
) => {
  setStatus(status);
  setMessage(message);
  setTimeout(() => {
    setStatus('idle');
  }, SNACKBAR_TIMEOUT);
  setTimeout(() => {
    setMessage('');
  }, SNACKBAR_TIMEOUT + 500);
};

export const isChildren = (
  e: any,
  id: string,
  index: number,
  depth: number
): boolean => {
  if (index === depth) return false;
  const parent = e.target.parentNode;

  if (parent?.id === id) return true;
  return isChildren({ target: parent }, id, index + 1, depth);
};

/** Interfaces to make easier to handle differents kind of endpoint in one */
export interface ResponseFunctions {
  GET?: Function;
  POST?: Function;
  PUT?: Function;
  DELETE?: Function;
  PATCH?: Function;
  OPTIONS?: Function;
}

export const canEditSpec = (status: SpecStatus | undefined): boolean => {
  if (!status) return false;
  return [
    specStatus.DRAFT,
    specStatus.PROCESSING,
    specStatus.CHANGES_REQUIRED,
    specStatus.APPROVED,
    specStatus.PENDING_REVIEW,
  ].includes(status);
};

export const canSubmitSpec = (status: SpecStatus | undefined): boolean => {
  if (!status) return false;
  return [
    specStatus.DRAFT,
    specStatus.PROCESSING,
    specStatus.CHANGES_REQUIRED,
  ].includes(status);
};

export const canEditVideo = (status: SpecStatus | undefined): boolean => {
  if (!status) return false;
  return [
    specStatus.DRAFT,
    specStatus.CHANGES_REQUIRED,
    specStatus.APPROVED,
    specStatus.PENDING_REVIEW,
  ].includes(status);
};

export const canDeleteVideo = (status: SpecStatus | undefined): boolean => {
  if (!status) return false;
  return [
    specStatus.PROCESSING,
    specStatus.DRAFT,
    specStatus.CHANGES_REQUIRED,
    specStatus.APPROVED,
    specStatus.PENDING_REVIEW,
  ].includes(status);
};

export const statusChange = (status: SpecStatus | undefined): SpecStatus => {
  if (!status) return specStatus.PROCESSING;
  switch (status) {
    case specStatus.CHANGES_REQUIRED:
      return specStatus.DRAFT;
    case specStatus.SOLD:
    case specStatus.APPROVED:
    case specStatus.PROCESSING:
    case specStatus.PENDING_REVIEW:
    case specStatus.DRAFT:
    default:
      return status;
  }
};

export const hmsToSeconds = (hms: string) => {
  const a = hms.split(':');
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return seconds;
};

export const addZero = (num: number) => (num < 10 ? `0${num}` : num);

export const secondsToHms = (seconds: number): string => {
  if (seconds < 60) {
    return `:${addZero(seconds)}`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${addZero(minutes)}:${addZero(sec)}`;
  } else if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = (seconds % 3600) % 60;
    return `${addZero(hours)}:${addZero(minutes)}:${addZero(sec)}`;
  }

  return seconds + '';
};

export const imgto64 = (file: File) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = function() {
      const result = reader.result as string;
      resolve(result.replace('data:', '').replace(/^.+,/, ''));
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const getLastItem = (thePath: string) =>
  thePath.substring(thePath.lastIndexOf('/') + 1);

export const capitalizeFirstLetter = (text: string) => {
  const array = text.split(' ');

  for (let index = 0; index < array.length; index++) {
    array[index] = array[index].charAt(0).toUpperCase() + array[index].slice(1);
  }

  const result = array.join(' ');
  return result;
};

export const isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const isResourceReady = async (
  maxTries: number,
  tryNo: number,
  url: string,
  next: (url: string) => any,
  router: NextRouter
): Promise<any> => {
  try {
    const response = await fetch(url);
    if (tryNo === maxTries) {
      return router.reload();
    }

    if (response.status != 200) {
      return setTimeout(
        () => isResourceReady(maxTries, tryNo + 1, url, next, router),
        MUX_TIMEOUT_INTERVAL
      );
    } else {
      return next(url);
    }
  } catch (error) {
    return router.reload();
  }
};
