export enum ActionType {
  SET_USER = 'SET_USER',
  SET_CREATORID = 'SET_CREATORID',
  SET_USER_SETTINGS_DATA = 'SET_USER_SETTINGS_DATA',
  SET_PROFESSIONS = 'SET_PROFESSIONS',
  SET_ERROR = 'SET_ERROR',
  RESET_LOAD_VIDEO = 'RESET_LOAD_VIDEO',
  SET_LOAD_VIDEO = 'LOAD_VIDEO',
  SET_LOAD_VIDEO_ERROR = 'SET_LOAD_VIDEO_ERROR',
  SET_VIDEO_UPLOAD_ID = 'SET_VIDEO_UPLOAD_ID',
  SET_VIDEO_UPLOAD_PROGRESS = 'SET_VIDEO_UPLOAD_PROGRESS',
  SET_VIDEO_UPLOADED = 'SET_VIDEO_UPLOADED',
  SET_VIDEO_CANCEL = 'SET_VIDEO_CANCEL',
  SET_VIDEO_UPLOAD_CANCEL = 'SET_VIDEO_UPLOAD_CANCEL',
  SET_SNACKBAR = 'SET_SNACKBAR',
  SET_SPECS = 'SET_SPECS',
  SET_SPEC_TYPES = 'SET_SPEC_TYPES',
  SET_INVITATION_DATA = 'SET_INVITATION_DATA',
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING',
  START_LOADING_SCREEN = 'START_LOADING_SCREEN',
  STOP_LOADING_SCREEN = 'STOP_LOADING_SCREEN',
  SET_GLITTERING = 'SET_GLITTERING',
  SET_INTRO_MESSAGE = 'SET_INTRO_MESSAGE',
  CLOSE_INTRO_MESSAGE = 'CLOSE_INTRO_MESSAGE',

  /** Release Forms Types */
  SET_LOAD_FILES = 'SET_LOAD_FILES',
  SET_UPLOAD_PROGRESS_FILE = 'SET_UPLOAD_PROGRESS_FILE ',
  SET_CANCEL_UPLOAD_FILE = 'SET_CANCEL_UPLOAD_FILE',
  SET_REMOVE_CANCELED_FILE = 'SET_REMOVE_CANCELED_FILE',
  SET_FILES = 'SET_FILES',
  SET_REMOVE_DUPLICATED_FILE = 'SET_REMOVE_DUPLICATED_FILE',

  /** Spec Info Types **/
  SET_SPEC_ID = 'SET_SPEC_ID',
  START_SPEC_INFO = 'START_SPEC_INFO',
  SET_SPEC_INFO = 'SET_SPEC_INFO',
  SET_SPEC_THUMBNAIL = 'SET_SPEC_THUMBNAIL',
  SET_SPEC_INFO_PARAM = 'SET_SPEC_INFO_PARAM',
  SET_AVAILABLE_KEYWORDS = 'SET_AVAILABLE_KEYWORDS',

  /** User Info Types **/
  SET_USER_INFO = 'SET_USER_INFO',
  SET_INTRO_VIDEO = 'SET_INTRO_VIDEO',
  SET_INTRO_THUMBNAIL = 'SET_VIDEO_THUMBNAIL',
  SET_USER_INFO_PARAM = 'SET_USER_INFO_PARAM',
  SET_USER_INFO_SOCIAL_NETWORK = 'SET_USER_INFO_SOCIAL_NETWORK',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',

  /** Auth user (Client side) */
  SET_AUTH_USER_SUCCESS = 'SET_AUTH_USER_SUCCESS',
  SET_AUTH_USER_ERROR = 'SET_AUTH_USER_ERROR',
  SET_AUTH_USER_NEWTOKEN = 'SET_AUTH_USER_NEWTOKEN',
  SET_AUTH_USER_LOGOUT = 'SET_AUTH_USER_LOGOUT',

  /** Spec Keywords */
  SET_KEYWORDS = 'SET_KEYWORDS',
  SET_HIVE_KEYWORDS = 'SET_HIVE_KEYWORDS',
  SET_ADD_KEYWORD = 'SET_ADD_KEYWORD',
  SET_KEYWORDS_UPDATE = 'SET_KEYWORDS_UPDATE',
  SET_KEYWORDS_REMOVE = 'SET_KEYWORDS_REMOVE',

  /** Notifications */
  SET_ALL_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_ADD_NOTIFICATION = 'SET_ADD_NOTIFICATION',
  SET_ADD_NOTIFICATION_ERROR = 'SET_NOTIFICATIONS_ERROR',
  SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ',
  SET_NOTIFICATION_AS_READ_ERROR = 'SET_NOTIFICATION_AS_READ_ERROR',
}
